.App {
  display: grid;
  grid-template-columns: minmax(100px, 20vw) auto;
  grid-template-rows: auto;
  grid-template-areas: "sidebar main";
  grid-auto-flow: row;
  height: 100svh;
  width: 100vw;
  overflow: hidden;
}

.main {
  overflow-y: scroll;
}

.sidebar {
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.audio-button {
  height: 14vh;
  min-width: 20vw !important;
}

.App-link {
  color: #61dafb;
}
.MuiTabs-flexContainer button.Mui-selected {
  color: red;
}
.MuiTabs-flexContainer button {
  border: 1px darkgray solid;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
